import Vue from 'vue'
import Router from 'vue-router'

import ApiService from '@/services/api';

// Containers
const PublicContainer = () => import('@/containers/PublicContainer');
const EmptyContainer = () => import('@/containers/EmptyContainer');

// Components
const HomeComponent = () => import('@/views/Home/HomeComponent');
const LoginComponent= () => import('@/views/Auth/LoginComponent');
const RecuperarCuentaComponent= () => import('@/views/Auth/RecuperarCuentaComponent');
const RecuperarContrasenyaComponent= () => import('@/views/Auth/RecuperarContrasenyaComponent');


const VideoComponent= () => import('@/views/Video/VideoComponent');
const CanalesComponent =  () => import('@/views/Canales/Canales');
const VerCanal = () => import('@/views/Canales/VerCanal');

const PanelAdmin = () => import('@/views/Admin/Panel/PanelAdmin');
const UsuariosAdmin = () => import('@/views/Admin/Panel/Usuarios/UsuariosAdmin');
const UsuariosDetalle = () => import('@/views/Admin/Panel/Usuarios/UsuariosDetalle');
const NuevoUsuario = () => import('@/views/Admin/Panel/Usuarios/CrearUsuario');
const NodosAdmin = () => import('@/views/Admin/Panel/Nodos/NodosAdmin');
const NuevoNodo = () => import('@/views/Admin/Panel/Nodos/CrearNodo');
const NodoDetalle = () => import('@/views/Admin/Panel/Nodos/NodoDetalle');
const DelegacionesAdmin = () => import('@/views/Admin/Panel/Delegaciones/DelegacionAdmin');
const DelegacionesDetalle  = () => import('@/views/Admin/Panel/Delegaciones/DelegacionDetalle');
const NuevaDelegacion = () => import('@/views/Admin/Panel/Delegaciones/CrearDelegacion');
const VideosAdmin = () => import('@/views/Admin/Panel/Videos/VideosAdmin');
const VideoDetalle = () => import('@/views/Admin/Panel/Videos/VideoDetalle');
const NuevoVideo = () => import('@/views/Admin/Panel/Videos/CrearVideo');
const EventosAdmin = () => import('@/views/Admin/Panel/Eventos/EventosAdmin');
const EventoDetalle = () => import('@/views/Admin/Panel/Eventos/EventoDetalle');
const CanalesAdmin = () => import('@/views/Admin/Panel/Canales/CanalesAdmin');
const ProfileComponent = () => import('@/views/Profile/ProfileComponent');






Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [

    {
      path: '/',
      title: 'AGUSTINOS.TV',
      redirect: '/auth/login',
      name: 'Inicio',
      component: PublicContainer,
      children: [
        {
          path: '/home',
          name: 'Home',
          component: HomeComponent,
          meta: {
            auth : true
          }
        },
        {
          path: '/video/:id',
          name: 'Video',
          component: VideoComponent,
          meta: {
            auth : true
          }
        },
        {
          path: '/canales',
          name: 'Canales',
          component: CanalesComponent,
          meta: {
            auth : true
          }
        },
        {
          path: '/perfil',
          name: 'Pefil',
          component: ProfileComponent,
          meta: {
            auth : true
          }
        },
        {
          path: '/canal/:id',
          name: 'Canal',
          component: VerCanal,
          meta: {
            auth : true
          }
        },
        {
          path: '/admin',
          name: 'Panel Administrativo',
          component: PanelAdmin,
          meta: {
            auth : true
          },
        },
        {
          path: '/admin/usuarios',
          name: 'Panel Administración > Usuarios',
          component: UsuariosAdmin,
          meta: {
            auth : true
          }
        },
        {
          path: '/admin/nuevo-usuario',
          name: 'Panel Administración > Usuarios > Nuevo',
          component: NuevoUsuario,
          meta: {
            auth: true
          }
        },
        {
          path: '/admin/usuarios/:id',
          name: 'Panel Administración > Usuarios > Detalle',
          component: UsuariosDetalle,
          meta: {
            auth : true
          }
        },
        {
          path: '/admin/nodos',
          name: 'Panel Administración > Nodos',
          component: NodosAdmin,
          meta: {
            auth : true
          }
        },
        {
          path: '/admin/nuevo-nodo',
          name: 'Panel Administración > Nodos > Nuevo',
          component: NuevoNodo,
          meta: {
            auth: true
          }
        },
        {
          path: '/admin/nodos/:id',
          name: 'Panel Administración > Nodos > Detalle',
          component: NodoDetalle,
          meta: {
            auth : true
          }
        },
        {
          path: '/admin/delegaciones',
          name: 'Panel Administración > Delegaciones',
          component: DelegacionesAdmin,
          meta: {
            auth : true
          }
        },
        {
          path: '/admin/nueva-delegacion',
          name: 'Panel Administración > Delegaciones > Nueva',
          component: NuevaDelegacion,
          meta: {
            auth: true
          }
        },
        {
          path: '/admin/delegaciones/:id',
          name: 'Panel Administración > Delegaciones > Detalle',
          component: DelegacionesDetalle,
          meta: {
            auth : true
          }
        },
        {
          path: '/admin/videos',
          name: 'Panel Administración > Vídeos',
          component: VideosAdmin,
          meta: {
            auth : true
          }
        },
        {
          path: '/admin/videos/:id',
          name: 'Panel Administración > Vídeos > Detalle',
          component: VideoDetalle,
          meta: {
            auth : true
          }
        },
        {
          path: '/admin/nuevo-video',
          name: 'Panel Administración > Vídeos > Nuevo',
          component: NuevoVideo,
          meta: {
            auth: true
          }
        },
        {
          path: '/admin/eventos',
          name: 'Panel Administración > Eventos',
          component: EventosAdmin,
          meta: {
            auth : true
          }
        },
        {
          path: '/admin/eventos/:id',
          name: 'Panel Administración > Eventos > Detalle',
          component: EventoDetalle,
          meta: {
            auth: true
          }
        },
        {
          path: '/admin/canales',
          name: 'Panel Administración > Canales',
          component: CanalesAdmin,
          meta: {
            auth : true
          }
        },
      ]
    },
    {
      path: '/auth',
      redirect: '/auth/login',
      name: 'Auth',
      component: EmptyContainer,
      children: [
        {
          path: '/auth/login',
          name: 'Login',
          component: LoginComponent,
          meta: {
            auth : false
          }
        },
        {
          path: '/auth/recuperar-cuenta',
          name: 'Recuperar Cuenta',
          component: RecuperarCuentaComponent,
          meta: {
            auth : false
          }
        },
        {
          path: '/auth/recuperar-contrasenya',
          name: 'Recuperar Contrasenya',
          component: RecuperarContrasenyaComponent,
          meta: {
            auth : false
          }
        },
      ]
    },
    {
      path: '*',
      redirect: '/auth/login'
    }
  ]
})


let checkValid = async() => await ApiService.getMe().then(response => response).catch(e => e);
router.beforeEach(async(to, from, next) => {
  let token = localStorage.getItem('token');
  let user, expired = false;
  if (token) {
    let response = await checkValid();
    if (response.status == 200 ) {  // && !response.data.error == 'Unauthorized'

      localStorage.setItem('email',response.data.email);
      localStorage.setItem('user_id',response.data.id);
      localStorage.setItem('username',response.data.username);
      // TODO. api status error si token está caducado
      user = response.data;
    } else {
      localStorage.removeItem('token');
      token = null;
      expired = true;
    }
  }
  if (to.meta && to.meta.auth == true) {
    // llamar getUser para comprobar la validación del token
    if (token) {
      to.query.user = user;
      next();
    } else {
      next({
        path: '/login',
      });
    }
  } else if (to.meta && to.meta.auth == false) {
    if (token) {
      next({
        path: '/',
        query: { user: user }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router;
