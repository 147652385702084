import axios from 'axios';
import { BASE_URL } from '@/config';
import { API_DOMAIN } from '../config';
    

export default {

    login(email, password) {
        return axios.post(`${API_DOMAIN}/auth/login`, {
          "email": email,
          "password": password,
          "remember_me": true
        }, {
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
          }
        });
      },
    registrar(username,email, password) {
        return axios.post(`${API_DOMAIN}/auth/register`, {
            "email": email,
            "password": password,
            "username": username,
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token'),
            }
        });
    },
      getMe() {
        return axios.get(`${API_DOMAIN}/auth/user`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
      },
    PedirActivacion(email) {
        return axios.get(`${API_DOMAIN}/auth/ask-activate/${email}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            }
        });
    },
    PedirRecuperarContrasenya(email) {
        return axios.post(`${API_DOMAIN}/auth/password-recovery/ask`, {
            "email": email,
        },{
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            }
        });
    },
    RecuperarContrasenya(token,password) {
        return axios.post(`${API_DOMAIN}/auth/password-recovery/update`, {
            "token": token,
            "password": password,
        },{
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            }
        });
    },
    isMobile(){
        return axios.get(`${API_DOMAIN}/auth/isMobile`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    logout() {
        return axios.post(`${API_DOMAIN}/auth/logout`,{}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    getNovedades(pag,nombre = null,delegacion_id = null,tipo = null,orden = null) {

        let filtro = '';
        if(nombre != null)
        {
            filtro += '&title=' + nombre;
        }
        if(delegacion_id != null)
        {
            filtro += '&delegacion_id=' + delegacion_id;
        }
        if(tipo != null)
        {
            filtro += '&tipo=' + tipo;
        }
        if(orden != null)
        {
            filtro += '&orden=' + orden;
        }

        return axios.get(`${API_DOMAIN}/novedades?page=${pag}${filtro}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    VerVideo(id){
        return axios.get(`${API_DOMAIN}/ver-video/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    addViewtoVideo(id){
        return axios.post(`${API_DOMAIN}/videos/${id}/addview`,{}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    getImagenVideo(id){
        return axios.get(`${API_DOMAIN}/imagenes/video/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    getCanales(pag = 1) {
        return axios.get(`${API_DOMAIN}/canales?page=${pag}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    VerCanal(id) {
        return axios.get(`${API_DOMAIN}/ver-canal/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    ViewEvento(id) {
        return axios.post(`${API_DOMAIN}/eventos/ver/${id}`,{}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    getEventoActivo(canal_id) {
        if(canal_id) {
            return axios.post(`${API_DOMAIN}/eventos/evento_activo_en_canal/${canal_id}`, {}, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('token')
                }
            });
        }
    },
    getGenteOnlineStreaming() {
        return axios.post(`${API_DOMAIN}/rtmp/genteOnline`, {},{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },

    getLikesFromEvento(evento_id){
        return axios.get(`${API_DOMAIN}/likes/evento/${evento_id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    getLikesFromVideo(video_id){
        return axios.get(`${API_DOMAIN}/likes/video/${video_id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },

    checkLikeEvento(evento_id){
        return axios.get(`${API_DOMAIN}/check-like/evento/${evento_id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    checkLikeVideo(video_id){
        return axios.get(`${API_DOMAIN}/check-like/video/${video_id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    AddLikeEvento(evento_id){
        return axios.post(`${API_DOMAIN}/likes/evento/${evento_id}`,{}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    AddLikeVideo(video_id){
        return axios.post(`${API_DOMAIN}/likes/video/${video_id}`,{}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    QuitLikeVideo(video_id){
        return axios.delete(`${API_DOMAIN}/likes/video/${video_id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },
    QuitLikeEvento(evento_id){
        return axios.delete(`${API_DOMAIN}/likes/evento/${evento_id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },

    getEventos(pag = 1,canal_id = null,orden = null,limite = null,estado = null,estado2 = null) {

        if(canal_id != null && orden != null && limite != null && estado != null && estado2 != null)
        {
            return axios.get(`${API_DOMAIN}/eventos?page=${pag}&canal_id=${canal_id}&orden=${orden}&limite=${limite}&estado=${estado}&estado=${estado2}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+localStorage.getItem('token')
                }
            });

        }
        else if(canal_id == null && orden != null && limite != null)
        {
            return axios.get(`${API_DOMAIN}/eventos?page=${pag}'&orden=${orden}&limite=${limite}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+localStorage.getItem('token')
                }
            });

        }
        else
        {
            return axios.get(`${API_DOMAIN}/eventos?pag=${pag}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+localStorage.getItem('token')
                }
            });
        }

    },
    getEvento(id) {

        return axios.post(`${API_DOMAIN}/eventos/${id}`, {},{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },

    getUsers(pag,order = 'DESC',limite = 25) {

            return axios.get(`${API_DOMAIN}/users?page=${pag}&order=${order}&limite=${limite}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+localStorage.getItem('token')
                }
            });

    },

    getUser(id) {

        return axios.get(`${API_DOMAIN}/users/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    getUserAcitivdad(id,page = 1) {

        return axios.post(`${API_DOMAIN}/users/actividad/${id}/${page}`, {},{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },

    UserCambiarNombre(id,nombre) {

        return axios.post(`${API_DOMAIN}/users/change-name/${id}`, {
            "username" : nombre,
        },{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    UserCambiarAvisos(id,avisos) {

        return axios.post(`${API_DOMAIN}/users/change-notifications/${id}`, {
            "acepto_avisos_contenido" : avisos,
        },{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    UserDeleteAccount(id) {

        return axios.post(`${API_DOMAIN}/users/delete-account/${id}`, {
        },{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    UserCambiarEmail(id,email) {

        return axios.post(`${API_DOMAIN}/users/change-email/${id}`, {
            "email" : email,
        },{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },

    updateUser(user) {

        return axios.put(`${API_DOMAIN}/users/${user.id}`, {
            "username" :  user.username,
            "email" : user.email,
            "active" : user.active,
            "role" : user.role,
        },{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },

    deleteUser(user) {

        return axios.delete(`${API_DOMAIN}/users/${user}`,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },

    createUser(user) {

        return axios.post(`${API_DOMAIN}/users`,{
            "username" :  user.username,
            "email" : user.email,
            "active" : user.active,
            "role" : user.role,
        },{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },

    uploadVideo(id,video_upload_title,formData) {

        return axios.post(`${API_DOMAIN}/videos/upload/${id}/${video_upload_title}`,formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },

    createNodo(nodo) {

        return axios.post(`${API_DOMAIN}/nodos`,{
            "nombre" :  nodo.nombre,
            "url" : nodo.url,
            "estado" : nodo.estado,
            "capacidad" : nodo.capacidad,
            "ruta_videos_src" : nodo.ruta_videos_src,
            "ruta_videos_originales" : nodo.ruta_videos_originales,
            "ruta_imagenes" : nodo.ruta_imagenes,
        },{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    updateNodo(nodo) {

        return axios.put(`${API_DOMAIN}/nodos/${nodo.id}`,{

            "nombre" :  nodo.nombre,
            "url" : nodo.url,
            "estado" : nodo.estado,
            "capacidad" : nodo.capacidad,
            "ruta_videos_src" : nodo.ruta_videos_src,
            "ruta_videos_originales" : nodo.ruta_videos_originales,
            "ruta_imagenes" : nodo.ruta_imagenes,
        },{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    getNodo(id) {

        return axios.get(`${API_DOMAIN}/nodos/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    deleteNodo(nodo) {

        return axios.delete(`${API_DOMAIN}/nodos/${nodo}`,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    createDelegacion(delegacion,fichero_nombre,fichero_contenido) {

        return axios.post(`${API_DOMAIN}/delegaciones`,{
            "nombre" :  delegacion.nombre,
            "web" : delegacion.web,
            "imagen" : delegacion.imagen,
            "fichero_contenido" : fichero_contenido,
            "fichero_nombre" : fichero_nombre,
        },{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },

    updateDelegacion(delegacion,fichero_nombre,fichero_contenido) {

        return axios.put(`${API_DOMAIN}/delegaciones/${delegacion.id}`,{
            "nombre" :  delegacion.nombre,
            "web" : delegacion.web,
            "imagen" : delegacion.imagen,
            "fichero_contenido" : fichero_contenido,
            "fichero_nombre" : fichero_nombre,
        },{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    deleteDelegacion(d) {

        return axios.delete(`${API_DOMAIN}/delegaciones/${d}`,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    getDelegacion(id) {

        return axios.get(`${API_DOMAIN}/delegaciones/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },


    getPlanners(pag,order = 'DESC',limite = 25) {

        return axios.get(`${API_DOMAIN}/planner?page=${pag}&order=${order}&limite=${limite}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },

    getNodos(pag,order = 'DESC',limite = 25) {

        return axios.get(`${API_DOMAIN}/nodos?page=${pag}&order=${order}&limite=${limite}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    getDelegaciones(pag,order = 'DESC',limite = 25) {

        return axios.get(`${API_DOMAIN}/delegaciones?page=${pag}&order=${order}&limite=${limite}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    getVideos(pag,order = 'DESC',limite = 25) {

        return axios.get(`${API_DOMAIN}/videos?page=${pag}&order=${order}&limite=${limite}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    getVideo(id) {

        return axios.get(`${API_DOMAIN}/videos/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },
    createVideo(video,fichero_nombre,fichero_contenido) {

        return axios.post(`${API_DOMAIN}/videos/create`,{
            "title" :  video.nombre,
            "details" : video.descripcion,
            "estado" : video.estado,
            "delegacion_id" : video.delegacion,
            "user_id" : video.user_id,
            "fichero_contenido" : fichero_contenido,
            "fichero_nombre" : fichero_nombre,
        },{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },

    UdpateVideo(video,fichero_nombre = null,fichero_contenido = null) {

        if(fichero_nombre == null && fichero_contenido == null)
        {
            return axios.post(`${API_DOMAIN}/videos/${video.id}`,{
                "title" :  video.title,
                "details" : video.details,
                "estado" : video.estado,
                "delegacion_id" : video.delegacion_id,
                "user_id" : video.user_id,
            },{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+localStorage.getItem('token')
                }
            });

        }
        else
        {
            return axios.post(`${API_DOMAIN}/videos/${video.id}`,{
                "title" :  video.title,
                "details" : video.details,
                "estado" : video.estado,
                "delegacion_id" : video.delegacion_id,
                "user_id" : video.user_id,
                "fichero_contenido" : fichero_contenido,
                "fichero_nombre" : fichero_nombre,
            },{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+localStorage.getItem('token')
                }
            });

        }


    },
    deleteVideo(d) {

        return axios.delete(`${API_DOMAIN}/videos/${d}`,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });

    },

    getAdminHomeStats(){
        return axios.get(`${API_DOMAIN}/admin/home`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },

    subirGrabacion(id){
        return axios.post(`${API_DOMAIN}/grabaciones/subir/${id}`,{}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token')
            }
        });
    },






}
