<template>
  <router-view :key="$route.fullPath"></router-view>
</template>



<script>

import ApiService from '@/services/api';




export default {
  name: 'app',
  data() {
    return {
      user: null,
    }
  },
  created() {
    if (localStorage.getItem('token')) {

      ApiService.getMe().then(response => {
        console.log(response);
        
        if (response.status == 200) {
          this.user = response.data;
          console.log(this.user);
          localStorage.setItem('username', this.user.username);
          localStorage.setItem('user_id', this.user.id);
          localStorage.setItem('email', this.user.email);
        }
        if (response.data.error) {
          localStorage.removeItem('token');
          this.$router.push('/login');
        }
      }).catch(error => {
        console.log(error);
        localStorage.removeItem('token');
        this.$router.push('/login');
      });
    
    }
  }
}
</script>

