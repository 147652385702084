import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import './registerServiceWorker'
import moment from 'moment'
import BootstrapVue from 'bootstrap-vue';
import axios from 'axios'

import VueAxios from 'vue-axios'


import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import LoadScript from 'vue-plugin-load-script';
Vue.use(VueSweetalert2);
Vue.use(LoadScript);
Vue.use(VueAxios, axios)

Vue.loadScript("https://cdn.jsdelivr.net/npm/hls.js@latest");




import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'

import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline);


Vue.use(BootstrapVue);
Vue.use(require('vue-moment'));


Vue.config.productionTip = false;
window.moment = moment;

new Vue({
  render: h => h(App),
  store,
  router,
  components: { App }
}).$mount('#app')